html {
  font-size: 16px;
  height: 100%;
}

body {
  margin: 0;
  font-family: "Barlow";
  max-width: 500px;
}
