.resultScreen {
  //background-color: #423d3d;
  // background: url("https://onequiz.games.onefc.com/assets/pvp/results.png");
  // background-repeat: no-repeat;
  // background-size: cover;
  position: relative;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}
.result {
  padding-top: 2.13rem;
  text-align: center;
  color: #f0d50a;
  font-weight: 700;
  font-size: 9vw;
}
.result_text {
  margin: 0.38rem 1.63rem;
  color: #cccccc;
  font-weight: 600;
  font-size: 0.88rem;
  text-align: center;
}
.user_result_detail {
  display: flex;
  justify-content: space-around;
  position: relative;
}
.first_user_img {
  height: 3.75rem;
  width: 3.75rem;
  border-radius: 50%;
  margin-top: 0.2rem;
  // margin-bottom: 0.5rem;
  //margin-bottom: 1.375rem;
  //margin-left: 1.88rem;
}
.first_userName {
  color: #ffffff;
  font-weight: 700;

  font-size: 0.9135rem;
}
.first_userPoint {
  color: #4c8bff;
  font-weight: 700;

  font-size: 12vw;
  //margin-left: 3.5rem;
}
.first_user_box {
  margin-top: 5.5rem;
  margin-right: 2rem;
  text-align: center;
  position: relative;
}
.second_user_box {
  margin-top: 5.5rem;
  margin-left: 2rem;
  text-align: center;
  position: relative;
}
.second_userName {
  color: #ffffff;
  font-weight: 700;
  margin-top: 1.6rem;
  font-size: 0.9135rem;
}
.second_userPoint {
  color: #ff5239;
  font-weight: 700;

  font-size: 12vw;
  //margin-left: 2.8rem;
}
.second_user_img {
  height: 3.75rem;
  width: 3.75rem;
  //margin-bottom: 0.5rem;
  //margin-bottom: 1.375rem;
  border-radius: 50%;
  //margin-left: 1rem;
}
.playAgain_button {
  width: 60vw;
  height: 2.5rem;
  font-size: 1.25rem;
  background-color: #f0d50a;
  color: #222222;
  letter-spacing: 0;
  text-align: center;
  font-weight: 700;

  border: none;
  margin-bottom: 0.88rem !important;
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
}
.exit_button {
  width: 16.94rem;
  height: 2.5rem;
  font-size: 1.25rem;
  color: #f0d50a;
  background-color: #423d3d;
  border: 1px solid #f0d50a;
  letter-spacing: 0;
  text-align: center;
  font-weight: 700;
}
.button_box {
  margin-top: 6rem;
  text-align: center;
}
.dotted_border {
  height: 10rem;
  position: absolute;
  z-index: -1;
  top: 10rem;
  bottom: 0;
  left: 50%;
  border-left: 4px dashed rgba(255, 255, 255, 0.2);
}
.img_resultsBox {
  position: absolute;
  top: 7.5rem;
  z-index: -1;
  width: 94vw;
  height: auto;
}
.img_belt {
  height: 3.3rem;
  width: 5rem;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -10%);
}
.img_belt_right {
  height: 3.3rem;
  width: 5rem;
  position: absolute;
  top: 2.75rem;
  right: -0.2rem;
}
.first_user_img_div,
.second_user_img_div {
  background: url(https://onequiz.games.onefc.com/assets/pvp/bluering.svg);
  background-repeat: no-repeat;
  background-size: 5rem;
  padding: 0.25rem;
  margin-bottom: 1.375rem;
  background-position: center;
  position: relative;
}
// .second_user_img_div {
//   background-image: url(https://onequiz.games.onefc.com/assets/pvp/redring.svg);
// }

.boxing {
  position: absolute;
  height: 6rem;
  top: 4rem;
}

.boxing {
  position: absolute;
  height: 6rem;
  top: 4rem;
}
.overlay3 {
  position: absolute;
  transition: 0.5s ease;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 50% !important;
  z-index: -1;
}
.video {
  object-fit: initial;
  width: 100vw;
  height: 100vh;
}
.fetchresult {
  color: white;
}
